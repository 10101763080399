<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrando</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>registros</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="8"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="query"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
              />
              <v-select
                v-model="statusFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="descripcion"
                :options="statusOptions"
                class="invoice-filter-select d-inline-block mr-1"
                placeholder="Filtros Ose"
                @input="chageStatusFilter"
              />
              <b-button
                class="mr-50"
                variant="success"
                @click="generarReportes()"
              >
                <span class="text-nowrap">Reportes</span>
              </b-button>
              <b-button
                variant="primary"
                @click="openForm('new')"
              >
                <span class="text-nowrap">Nuevo</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="items"
        responsive
        :fields="fields"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No se encontraron registros"
        :busy="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner
              variant="primary"
              class="align-middle"
            />
            <strong class="ml-1">Cargando...</strong>
          </div>
        </template>
        <template #cell(razonSocial)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="`data:image/jpeg;base64,${data.item.foto}`"
                :text="avatarText(`${data.item.razonSocial}`)"
                :variant="`light-${getColorAvatar()}`"
              />
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.razonSocial }}
            </span>
            <small class="text-muted">{{ data.item.representanteLegal }}</small>
            <small class="text-muted d-block ">{{ data.item.tipoDocumento.abreviatura }} {{ data.item.documento }}</small>
          </b-media>
        </template>
        <!-- Column: estado_sunat -->
        <template #cell(estado_sunat)="data">
          <b-badge
            v-if="data.item.repuestaOse == null"
            variant="primary"
          >
            Pendiente
          </b-badge>
          <b-badge
            v-if="data.item.repuestaOse != null"
            variant="success"
          >
            Enviado
          </b-badge>

        </template>
        <!-- Column: estado_venta -->
        <template #cell(estado_venta)="data">
          <div class="d-flex align-items-center">
            <div>
              <div class="font-small-4 font-weight-bolder">
                <b-badge
                  v-if="data.item.fechaAnulacion == null"
                  variant="success"
                >
                  Vigente
                </b-badge>
                <b-badge
                  v-if="data.item.fechaAnulacion != null"
                  variant="danger"
                >
                  Anulado
                </b-badge>
              </div>
              <div class="font-small-3 text-muted">
                {{ data.item.fechaAnulacion }}
              </div>
            </div>
          </div>

        </template>
        <!-- Column: Actions -->
        <template #cell(acciones)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="data.item.fechaAnulacion == null"
              @click="deleteVenta(data)"
            >
              <feather-icon icon="XSquareIcon" />
              <span class="align-middle ml-50">Anular</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.repuestaOse != null"
              target="_blank"
              :href="data.item.repuestaOse == null ?'':'https://www.nubefact.com/see_invoice/'+data.item.repuestaOse.keyOse+'?view=true'"
              @click="getPrintPdf(data)"
            >
              <feather-icon icon="PrinterIcon" />
              <span class="align-middle ml-50">PDF, XML Y CDR</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.repuestaOse != null && data.item.fechaAnulacion == null"
              @click="generarNota(data)"
            >
              <feather-icon icon="BookIcon" />
              <span class="align-middle ml-50">Generar Nota Credito</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.rutaNotaCredito != null && data.item.fechaAnulacion != null"
              target="_blank"
              :href="data.item.rutaNotaCredito"
            >
              <feather-icon icon="BookIcon" />
              <span class="align-middle ml-50"> Pdf Nota Credito</span>
            </b-dropdown-item>
            <!-- <b-dropdown-item
              v-if="data.item.repuestaOse != null && data.item.fechaAnulacion == null"
              @click="generarGuia(data)"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Generar Guia</span>
            </b-dropdown-item> -->
            <b-dropdown-item
              v-if="data.item.repuestaOse == null"
              target="_blank"
              :href="data.item.repuestaOse == null ?'':data.item.repuestaOse.rutaPdf"
              @click="getConsultaOse(data)"
            >
              <feather-icon icon="PrinterIcon" />
              <span class="align-middle ml-50">Consultar Ose</span>
            </b-dropdown-item>

            <!-- <b-dropdown-item @click="getEdit(data)">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Ver</span>
            </b-dropdown-item>

            <b-dropdown-item @click="getEdit(data)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>

            <b-dropdown-item @click="getDelete(data)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Eliminar</span>
            </b-dropdown-item> -->
          </b-dropdown>
        </template>
        <template #cell(totalFormat)="data">
          {{ formatearMoneda(data.item.total) }}
        </template>
        <template #cell(comprobante)="data">
          <div class="d-flex align-items-center">
            <div>
              <div class="font-small-4 font-weight-bolder">
                {{ data.item.numeroComprobante }}
              </div>
              <div class="font-small-3 text-muted">
                {{ data.item.fechaVenta }}
              </div>
            </div>
          </div>
        </template>
        <template #cell(clienteTemplate)="data">
          <div class="d-flex align-items-center">
            <div>
              <div class="font-small-3 font-weight-bolder">
                {{ data.item.clienteNombre }}
              </div>
              <div class="font-small-2 text-muted">
                RUC: {{ data.item.clienteRuc }}
              </div>
            </div>
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando {{ from }} a {{ to }} de {{ of }} registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="page"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <b-modal
      ref="my-modal"
      hide-footer
      title=""
    >
      <div class="d-block">
        <h4 class="text-center">
          REPORTES DE LOS LIBROS Y/O REGISTROS ELECTRÓNICOS</h4>
        <div>
          <b-form-select
            v-model="selectedPeriodo"
            :options="options"
            size="sm"
            class="mt-1 mb-2"
          />
          <b-form-radio
            v-model="tipoRepo"
            plain
            name="some-radios3"
            value="op1"
            class="mb-2"
          >
            REGISTRO DEL INVENTARIO PERMANENTE EN UNIDADES FÍSICAS
          </b-form-radio>
          <b-form-radio
            v-model="tipoRepo"
            plain
            name="some-radios3"
            value="op2"
          >
            REGISTRO DEL INVENTARIO PERMANENTE VALORIZADO
          </b-form-radio>
          <b-form-radio
            v-model="tipoRepo"
            plain
            name="some-radios3"
            value="op3"
          >
            REGISTRO DE VENTA CONTASIS
          </b-form-radio>
        </div>
      </div>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="mt-2"
        variant="primary"
        block
        @click="initReporte"
      >
        Generar
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="mt-3"
        variant="secondary"
        block
        @click="hideModal"
      >
        Cerrar
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BDropdown, BDropdownItem, BPagination, BSpinner, BAvatar, BMedia, VBTooltip, BBadge, BFormRadio, BFormSelect,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { showToast, showError } from '@/helpers'
import { avatarText } from '@core/utils/filter'
import exportFromJSON from 'export-from-json'
// import cliente from '@/store/modules/comercial/actions/cliente'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BBadge,
    BFormRadio,
    vSelect,
    BSpinner,
    BAvatar,
    BMedia,
    BFormSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },

  data: () => ({
    isBusy: false,
    idEmpresa: 3,
    isToggleSidebar: false,
    dataEdit: {},
    formType: '',
    page: 1,
    perPage: 10,
    totalRows: 0,
    totalItems: 0,
    totalPage: 0,
    from: 0,
    to: 0,
    of: 0,
    pageOptions: [10, 20, 50],
    sortBy: null,
    selected: null,
    sortDesc: false,
    sortDirection: 'asc',
    query: '',
    tipoRepo: '',
    items: [],
    itemsExportExcel: [],
    statusFilter: { id: '-1', descripcion: 'Todos' },
    statusOptions: [
      { id: '-1', descripcion: 'Todos' },
      { id: '1', descripcion: 'Enviados' },
      { id: '2', descripcion: 'Pendientes' },
      { id: '3', descripcion: 'Vigentes' },
      { id: '4', descripcion: 'Anulados' },
    ],
    userProfile: JSON.parse(localStorage.getItem('userProfile')),
    fields: [
      { key: 'ventaId', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
      { key: 'comprobante', label: 'Comprobante', sortable: true },
      { key: 'clienteTemplate', label: 'Cliente', sortable: true, tdClass: 'w180', thClass: 'w180' },
      // { key: 'numeroComprobante', label: 'Nro.Comprobante', sortable: true },
      // { key: 'formatoPago.descripcion', label: 'F. Pago', sortable: true, tdClass: 'text-center', thClass: 'text-center' },
      { key: 'tipoVenta.descripcion', label: 'T. Venta', sortable: true },
      { key: 'totalFormat', label: 'Total', sortable: true },
      { key: 'estado_venta', label: 'Estado' },
      { key: 'estado_sunat', label: 'Sunat' },
      { key: 'acciones' },
    ],
    selectedPeriodo: null,
    options: [],
  }),
  watch: {
    perPage: {
      handler() {
        this.listarRegistros()
      },
    },
    page: {
      handler() {
        this.listarRegistros()
      },
    },
    query: {
      handler() {
        this.listarRegistros()
      },
    },
  },
  mounted() {
    this.listarRegistros()
  },
  methods: {
    exporDataExcel(rows, periodo) {
      console.log(periodo)
      const fileName = 'REGISTRO_VENTA_CONTASIS' // `REGISTRO DE VENTA CONTASIS`
      const exportType = exportFromJSON.types.xls
      const data = []
      rows.forEach(row => {
        const fechaVencimiento = row.fechaVencimiento === null ? '' : row.fechaVencimiento
        const tipoComprobante = row.tipoDocumento.codigo
        const partComprobanteRepo = (row.numeroComprobante).split('-')
        const nSerieComprobante = partComprobanteRepo[0]
        const numeroComprobante = partComprobanteRepo[1]
        const partCliente = (row.clienteDato).split('|')
        const clienteTipoDocumento = partCliente[2].length === 8 ? '1' : '6'
        const clienteNumero = partCliente[2]
        const clienteNombre = partCliente[0]
        const valorExonerada = row.total
        const baseOperacionGravada = '0'
        const totalExonedara = row.total
        const totalInafecta = '0'
        const totalIgv = row.montoIgv
        const otrosTributos = '0'
        const importeComprobante = row.total
        const tipoCambio = '0'
        const rowData = { fechaVenta: row.fechaVenta, fecha_vencimiento: fechaVencimiento, tipo_comprobante: tipoComprobante, nserie_comprobante: nSerieComprobante, numero_comprobante: numeroComprobante, cliente_tipo_documento: clienteTipoDocumento, cliente_numero: clienteNumero, cliente_nombre: clienteNombre, valor_exonerada: valorExonerada, base_operacion_gravada: baseOperacionGravada, total_exonedara: totalExonedara, total_inafecta: totalInafecta, total_igv: totalIgv, otros_tributos: otrosTributos, importe_comprobante: importeComprobante, tipo_cambio: tipoCambio }
        data.push(rowData)
      })
      console.log(data)
      const withBOM = true
      exportFromJSON({ data, fileName, exportType, withBOM })
    },
    initReporte() {
      Vue.swal({
        title: 'Generando el archivo',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: async () => {
          Vue.swal.showLoading()
          if (this.tipoRepo === 'op3') {
            await store
              .dispatch('comercial/VENTAS_FIND_ALL', {
                idEmpresa: this.idEmpresa,
                limit: 1000000,
                query: '',
                page: 1,
                almacen: -1,
                sortBy: 'ventaId%7CDESC',
                filtro_venta: this.statusFilter.id,
                periodo: this.selectedPeriodo,
              })
              .then(response => {
                const data = response.items
                this.exporDataExcel(data, this.selectedPeriodo)
                Vue.swal.close()
                this.$refs['my-modal'].hide()
              })
              .catch(error => {
                this.showToast('Ha ocurrido un error', error, 'danger')
              })
          } else {
            await store.dispatch('comercial/REPORTES_CONTABLE', { periodo: this.selectedPeriodo, tipo: this.tipoRepo })
              .then(response => {
                console.log(response)
                const contenido = response.join('\n')
                const a = document.createElement('a')
                const archivo = new Blob([contenido], { type: 'text/plain' })
                const url = URL.createObjectURL(archivo)
                a.href = url
                let codigoRepo = '1301'
                if (this.tipoRepo === 'op2') {
                  codigoRepo = '1201'
                }
                a.download = `LE20362013802${this.selectedPeriodo}00${codigoRepo}00001111`
                a.click()
                URL.revokeObjectURL(url)
                Vue.swal.close()
                this.$refs['my-modal'].hide()
              })
          }
        },
      })
    },
    generarReportes() {
      Vue.swal({
        title: 'Obteniendo Parametros',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: async () => {
          Vue.swal.showLoading()
          await store.dispatch('comercial/REPORTES_PARAMETERS', {
          })
            .then(response => {
              this.options = response.periodos
              this.$refs['my-modal'].show()
            })
          Vue.swal.close()
        },
      })
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['my-modal'].toggle('#toggle-btn')
    },
    getPrintPdf(data) {
      if (data.item.repuestaOse !== null) {
        console.log(data.item.repuestaOse.rutaPdf)
      }
    },
    async listarRegistros() {
      // console.log(this.userProfile)
      this.isBusy = true
      await store
        .dispatch('comercial/VENTAS_FIND_ALL', {
          idEmpresa: this.idEmpresa,
          limit: this.perPage,
          query: this.query,
          page: this.page,
          almacen: -1,
          sortBy: 'ventaId%7CDESC',
          filtro_venta: this.statusFilter.id,
        })
        .then(response => {
          this.items = response.items.map(venta => ({ ...venta, clienteRuc: venta.clienteDato.split('|')[2], clienteNombre: venta.clienteDato.split('|')[0] }))
          this.totalRows = response.totalRows
          this.totalItems = response.totalItems
          this.totalPage = response.totalPage
          this.from = this.perPage * (this.page - 1) + (this.totalRows ? 1 : 0)
          this.to = this.perPage * (this.page - 1) + this.totalRows
          this.of = this.totalItems
        })
        .catch(error => {
          this.showToast('Ha ocurrido un error', error, 'danger')
        })
      this.isBusy = false
    },
    openForm(type) {
      // this.formType = type
      // this.isToggleSidebar = true
      console.log(type)
      this.$router.push({ name: 'rt-comercial-nuevaventa' })
    },
    formatearMoneda(valor) {
      // Convertir el número en cadena con dos decimales
      const valorFormateado = valor.toFixed(2)

      // Agregar el prefijo "S/." y retornar
      return `S/. ${valorFormateado}`
    },
    getEdit(data) {
      this.formType = 'edit'
      this.isToggleSidebar = true
      console.log(data.item)
      this.dataEdit = data.item
    },
    deleteVenta(data) {
      this.$swal({
        title: 'Está seguro?',
        text: 'Va eliminar un registro!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const partComprobante = data.item.numeroComprobante.split('-')
          const params = { nro_comprobante: partComprobante[1], serie: partComprobante[0], tipo_documento: data.item.tipoDocumento.tipoDocumentoId, venta_id: data.item.ventaId }
          this.$swal({
            title: 'Anulando en la Ose',
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            onOpen: async () => {
              this.$swal.showLoading()
              const service = 'comercial/VENTA_DELETE'
              await store.dispatch(service, params)
                .then(() => {
                  // this.items.forEach((item, index) => {
                  //   if (item.ventaId === data.item.ventaId) {
                  //     this.items[index].repuestaOse = response.respuesta_ose
                  //   }
                  // })
                  this.listarRegistros()
                  this.$swal.close()
                  this.showToast('Operación Extitosa', 'El comprobante se anulo', 'success')
                })
                .catch(error => {
                  this.$swal.close()
                  console.log(error)
                  this.showToast('Operación Error', error.errorMessage, 'danger')
                })
            },
          })
        }
      })
    },
    refetchData(response) {
      let message = 'El proceso se ha ejecutado satisfactoriamente.'
      if (response.message) {
        message = response.message
      }
      this.showToast('Operación Extitosa', message, 'success')
      this.listarRegistros()
    },
    getColorAvatar() {
      const max = 7
      const min = 1
      const status = Math.floor(Math.random() * ((max + 1) - min) + min)
      if (status === 1) return 'primary'
      if (status === 2) return 'danger'
      if (status === 3) return 'secondary'
      if (status === 4) return 'warning'
      if (status === 5) return 'info'
      if (status === 6) return 'success'
      return 'primary'
    },
    // deleteVenta
    // generarGuia
    // generarNota
    // getConsultaOse
    generarNota(data) {
      const partComprobante = data.item.numeroComprobante.split('-')
      const params = { nro_comprobante: partComprobante[1], serie: partComprobante[0], tipo_documento: data.item.tipoDocumento.tipoDocumentoId, venta_id: data.item.ventaId }
      this.$swal({
        title: 'Crear nota de credito....',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: async () => {
          this.$swal.showLoading()
          const service = 'comercial/NOTA_CREDITO_CREATE'
          await store.dispatch(service, params)
            .then(response => {
              // this.items.forEach((item, index) => {
              //   if (item.ventaId === data.item.ventaId) {
              //     this.items[index].repuestaOse = response.respuesta_ose
              //   }
              // })
              console.log(response)
              this.$swal.close()
              this.showToast('Operación Extitosa', 'La nota de credito  fue creada', 'success')
              this.listarRegistros()
            })
            .catch(error => {
              this.$swal.close()
              console.log(error)
              this.showToast('Operación Error', error.errorMessage, 'danger')
            })
        },
      })
    },
    getConsultaOse(data) {
      const partComprobante = data.item.numeroComprobante.split('-')
      const params = { nro_comprobante: partComprobante[1], serie: partComprobante[0], tipo_documento: data.item.tipoDocumento.tipoDocumentoId, venta_id: data.item.ventaId }
      this.$swal({
        title: 'Consultando en la Ose',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: async () => {
          this.$swal.showLoading()
          const service = 'comercial/VENTA_CONSULTA_OSE'
          await store.dispatch(service, params)
            .then(response => {
              this.items.forEach((item, index) => {
                if (item.ventaId === data.item.ventaId) {
                  this.items[index].repuestaOse = response.respuesta_ose
                }
              })
              this.$swal.close()
              this.showToast('Operación Extitosa', 'El comprobante esta enviado en la Ose', 'success')
            })
            .catch(error => {
              this.$swal.close()
              this.showToast('Operación Error', error.errorMessage, 'danger')
            })
        },
      })
    },
    chageStatusFilter() {
      console.log(this.statusFilter)
      this.listarRegistros()
    },
    avatarText,
    showToast,
    showError,
  },

}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style>
.w200{
  width: 350px !important;
}

</style>
